<template>
  <div
    v-if="data.fields"
  >
    <div class="two-columns-content delay-entry"
      :class="{
        'remove-padding-top': data.fields.removePadding ? data.fields.removePadding.indexOf('Top') > -1 : false,
        'remove-padding-bottom': data.fields.removePadding ? data.fields.removePadding.indexOf('Bottom') > -1 : false
      }">
      <h4 v-if="data.fields.headline">{{data.fields.headline}}</h4>
      <h2 v-if="data.fields.tagline">{{data.fields.tagline}}</h2>

      <div class="description" v-html="mdToHTML(data.fields.description)" v-if="data.fields.description"></div>

      <img class="column-image" :src="data.fields.image.fields.file.url" v-if="data.fields.image" />

      <el-row v-if="data.fields.cta && data.fields.ctaTarget">
        <el-button
          class="delay-entry cta-button"
          type="danger"
          round
          @click="goToLink(data.fields.ctaTarget)"
        >{{data.fields.cta}}</el-button>
      </el-row>
    </div>
  </div>
</template>

<script>
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

export default {
  name: 'TwoColumns',

  transition: 'page',

  props: [ 'data' ],

  components: {
    QuestionHeader: () => import('Components/base/QuestionHeader'),
    Icons: () => import('Components/base/Icons')
  },

  methods: {
    goToLink (target) {
      if (target.indexOf('https://') === 0) {
        window.location.href = target
        return
      }
      this.$router.push(target)
    },
    mdToHTML (markdown) {
      return documentToHtmlString(markdown)
    },
  }
}
</script>

<style scoped lang="scss">
.two-columns-wrapper {
  min-height: 200px;
  @include breakpoint($tablet) {
    width: 50%;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &.two-columns-background-color-light {
    background-color: rgba($gray-light, 0.6);
  }
  &.two-columns-background-color-Dark {
    background-color: rgba($text-main, 0.99);
    h4 {
      color: rgba(white, 0.99);
    }
    h2 {
      color: rgba(white, 0.5);
    }
  }
}
.two-columns-content {
  padding: 1em;
  position: relative;
  text-align: left;
  @include breakpoint($tablet) {
    padding: 1em 1em;
  }
  p {
    @include breakpoint($tablet) {
      font-size: 0.6em;
    }
  }
  &.remove-padding-top {
    padding-top: 0;
  }
  &.remove-padding-bottom {
    padding-bottom: 0;
  }
}
.website-container {
  h4 {
    margin-bottom: 1em;
  }
}

.column-image {
  max-width: 100%;
  height: auto;
  width: 100%;
  margin-bottom: 1em;
}
</style>
<style lang="scss">
.two-columns + .two-columns {
  padding-top: 0;
  margin-bottom: 1em;
}

.two-columns-content {
  .description {
    h4 {
      margin-top: 4em;
      margin-bottom: 1em;
    }
  }
}
</style>